<template>
  <component :is="'b-card'">
    <!-- Header: Personal Info -->
    <div class="d-flex">
      <feather-icon icon="HardDriveIcon" size="19" class="text-primary" />
      <h4 class="mb-0 ml-50 text-primary">
        {{ $t('Vergi Dairesi Ekleme') }}
        </h4>
    </div>

    <!-- Form: Personal Info Form -->
    <validation-observer ref="simpleRules">
      <b-form class="mt-1">
        <b-row>
          <!-- Field: Ad -->
          <b-col cols="6" md="6" lg="4">
            <b-form-group>
              <label for="sVergiDairesiAdi">
            {{ $t('Vergi Dairesi Adı') }}
            </label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Vergi Dairesi Adı"
              >
                <b-form-input
                  id="sVergiDairesiAdi"
                  v-model="VergiDairesiData.sVergiDairesiAdi"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6" md="6" lg="4">
            <b-form-group>
              <label for="sSaymanlikKodu">
            {{ $t('Saymanlık Kodu') }}
            </label>
              <validation-provider
                #default="{ errors }"
                rules="required|max:5"
                name="Saymanlık Kodu"
              >
                <b-form-input
                  id="sSaymanlikKodu"
                  type="text"
                  v-model="VergiDairesiData.sSaymanlikKodu"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6" md="6" lg="4">
            <b-form-group>
              <label for="sSehirKodu">
            {{ $t('Şehir Kodu') }}
            </label>
              <validation-provider
                #default="{ errors }"
                rules="required|max:2"
                name="Şehir Kodu"
              >
                <b-form-input
                  id="sSehirKodu"
                  type="text"
                  v-model="VergiDairesiData.sSehirKodu"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6" md="6" lg="4">
            <b-form-group>
              <label for="sSehirAdi">
            {{ $t('Şehir Adı') }}
            </label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Şehir Adı"
              >
                <b-form-input
                  id="sSehirAdi"
                  v-model="VergiDairesiData.sSehirAdi"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" lg="4">
            <b-form-group label="İlçe" label-for="">
              <label for="sIlce">
            {{ $t('İlçe') }}
            </label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="İlçe"
              >
                <b-form-input
                  id="sIlce"
                  v-model="VergiDairesiData.sIlce"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col>
            <save-button 
          :onClickSaveAndClose="saveVergiDairesi.bind(false)"
          :onClickSave="saveVergiDairesi.bind(false)"
          />
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </component>
</template>

<script>
import SaveButton from '@/components/SaveButton.vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import {
  required,
  min,
  max,
} from '@validations';

// Notify
import AlertService from '@/common/alert.service';

// Toast
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    SaveButton,
  },

  data() {
    return {
      required,
      min,
      max,
      VergiDairesiData: {
        id: 0,
        sSehirKodu: null,
        sSehirAdi: null,
        sIlce: null,
        sSaymanlikKodu: null,
        sVergiDairesiAdi: null,
      },
    };
  },

  methods: {
    saveVergiDairesi(isClose) {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$store
            .dispatch('saveVergiDaire', this.VergiDairesiData)
            .then(response => {
              if (response.statusCode === 200) {
                this.$toast({
                  component: ToastificationSuccess,
                  position: 'top-right',
                  props: { text: response.message },
                });
                if (isClose) {
                  this.$router.push({ name: 'manage-vergidaire' });
                }
              } else {
                AlertService.error(this, response.message);
              }
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">

</style>
