var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{tag:"component"},[_c('div',{staticClass:"d-flex"},[_c('feather-icon',{staticClass:"text-primary",attrs:{"icon":"HardDriveIcon","size":"19"}}),_c('h4',{staticClass:"mb-0 ml-50 text-primary"},[_vm._v(" "+_vm._s(_vm.$t('Vergi Dairesi Ekleme'))+" ")])],1),_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{staticClass:"mt-1"},[_c('b-row',[_c('b-col',{attrs:{"cols":"6","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',{attrs:{"for":"sVergiDairesiAdi"}},[_vm._v(" "+_vm._s(_vm.$t('Vergi Dairesi Adı'))+" ")]),_c('validation-provider',{attrs:{"rules":"required","name":"Vergi Dairesi Adı"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"sVergiDairesiAdi","state":errors.length > 0 ? false : null},model:{value:(_vm.VergiDairesiData.sVergiDairesiAdi),callback:function ($$v) {_vm.$set(_vm.VergiDairesiData, "sVergiDairesiAdi", $$v)},expression:"VergiDairesiData.sVergiDairesiAdi"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',{attrs:{"for":"sSaymanlikKodu"}},[_vm._v(" "+_vm._s(_vm.$t('Saymanlık Kodu'))+" ")]),_c('validation-provider',{attrs:{"rules":"required|max:5","name":"Saymanlık Kodu"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"sSaymanlikKodu","type":"text","state":errors.length > 0 ? false : null},model:{value:(_vm.VergiDairesiData.sSaymanlikKodu),callback:function ($$v) {_vm.$set(_vm.VergiDairesiData, "sSaymanlikKodu", $$v)},expression:"VergiDairesiData.sSaymanlikKodu"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',{attrs:{"for":"sSehirKodu"}},[_vm._v(" "+_vm._s(_vm.$t('Şehir Kodu'))+" ")]),_c('validation-provider',{attrs:{"rules":"required|max:2","name":"Şehir Kodu"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"sSehirKodu","type":"text","state":errors.length > 0 ? false : null},model:{value:(_vm.VergiDairesiData.sSehirKodu),callback:function ($$v) {_vm.$set(_vm.VergiDairesiData, "sSehirKodu", $$v)},expression:"VergiDairesiData.sSehirKodu"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6","md":"6","lg":"4"}},[_c('b-form-group',[_c('label',{attrs:{"for":"sSehirAdi"}},[_vm._v(" "+_vm._s(_vm.$t('Şehir Adı'))+" ")]),_c('validation-provider',{attrs:{"rules":"required","name":"Şehir Adı"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"sSehirAdi","state":errors.length > 0 ? false : null},model:{value:(_vm.VergiDairesiData.sSehirAdi),callback:function ($$v) {_vm.$set(_vm.VergiDairesiData, "sSehirAdi", $$v)},expression:"VergiDairesiData.sSehirAdi"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('b-form-group',{attrs:{"label":"İlçe","label-for":""}},[_c('label',{attrs:{"for":"sIlce"}},[_vm._v(" "+_vm._s(_vm.$t('İlçe'))+" ")]),_c('validation-provider',{attrs:{"rules":"required","name":"İlçe"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"sIlce","state":errors.length > 0 ? false : null},model:{value:(_vm.VergiDairesiData.sIlce),callback:function ($$v) {_vm.$set(_vm.VergiDairesiData, "sIlce", $$v)},expression:"VergiDairesiData.sIlce"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',{staticClass:"mt-2"},[_c('b-col',[_c('save-button',{attrs:{"onClickSaveAndClose":_vm.saveVergiDairesi.bind(false),"onClickSave":_vm.saveVergiDairesi.bind(false)}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }